<template>
  <b-overlay :show="isLoading" rounded="sm">
    <b-card>
      <div class="mb-3 w-100 d-flex align-items-center">
        <h1>Library</h1>
      </div>
      <b-row>
        <b-col
          @click="openImageDetails(image)"
          cols="2"
          v-for="image in libraryList"
          :key="image.mediaId"
          class="cursor-pointer"
        >
          <img
            v-if="isImage(image)"
            :src="handleImageUrl(image)"
            alt=""
            class="w-100 h-auto"
          />
          <div
            v-else-if="isPdf(image)"
            class="w-100 h-auto d-flex align-items-center justify-content-center"
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              fill="currentColor"
              class="bi bi-file-pdf"
              viewBox="0 0 16 16"
              style="height: 4rem; width: 4rem"
            >
              <path
                d="M4 0a2 2 0 0 0-2 2v12a2 2 0 0 0 2 2h8a2 2 0 0 0 2-2V2a2 2 0 0 0-2-2H4zm0 1h8a1 1 0 0 1 1 1v12a1 1 0 0 1-1 1H4a1 1 0 0 1-1-1V2a1 1 0 0 1 1-1z"
              />
              <path
                d="M4.603 12.087a.81.81 0 0 1-.438-.42c-.195-.388-.13-.776.08-1.102.198-.307.526-.568.897-.787a7.68 7.68 0 0 1 1.482-.645 19.701 19.701 0 0 0 1.062-2.227 7.269 7.269 0 0 1-.43-1.295c-.086-.4-.119-.796-.046-1.136.075-.354.274-.672.65-.823.192-.077.4-.12.602-.077a.7.7 0 0 1 .477.365c.088.164.12.356.127.538.007.187-.012.395-.047.614-.084.51-.27 1.134-.52 1.794a10.954 10.954 0 0 0 .98 1.686 5.753 5.753 0 0 1 1.334.05c.364.065.734.195.96.465.12.144.193.32.2.518.007.192-.047.382-.138.563a1.04 1.04 0 0 1-.354.416.856.856 0 0 1-.51.138c-.331-.014-.654-.196-.933-.417a5.716 5.716 0 0 1-.911-.95 11.642 11.642 0 0 0-1.997.406 11.311 11.311 0 0 1-1.021 1.51c-.29.35-.608.655-.926.787a.793.793 0 0 1-.58.029zm1.379-1.901c-.166.076-.32.156-.459.238-.328.194-.541.383-.647.547-.094.145-.096.25-.04.361.01.022.02.036.026.044a.27.27 0 0 0 .035-.012c.137-.056.355-.235.635-.572a8.18 8.18 0 0 0 .45-.606zm1.64-1.33a12.647 12.647 0 0 1 1.01-.193 11.666 11.666 0 0 1-.51-.858 20.741 20.741 0 0 1-.5 1.05zm2.446.45c.15.162.296.3.435.41.24.19.407.253.498.256a.107.107 0 0 0 .07-.015.307.307 0 0 0 .094-.125.436.436 0 0 0 .059-.2.095.095 0 0 0-.026-.063c-.052-.062-.2-.152-.518-.209a3.881 3.881 0 0 0-.612-.053zM8.078 5.8a6.7 6.7 0 0 0 .2-.828c.031-.188.043-.343.038-.465a.613.613 0 0 0-.032-.198.517.517 0 0 0-.145.04c-.087.035-.158.106-.196.283-.04.192-.03.469.046.822.024.111.054.227.09.346z"
              />
            </svg>
          </div>
        </b-col>
      </b-row>
      <div class="my-2 d-flex align-items-center justify-content-center">
        <b-pagination
          v-model="pageNumber"
          :total-rows="totalCount"
          :per-page="count"
          first-number
          align="center"
          last-number
          prev-class="prev-item"
          next-class="next-item"
        >
          <template #prev-text>
            <feather-icon
              icon="ChevronLeftIcon"
              size="18"
            />
          </template>
          <template #next-text>
            <feather-icon icon="ChevronRightIcon" size="18" />
          </template>
        </b-pagination>
      </div>
      <library-image-modal
        :mediaType="mediaType"
        :tempMedia="tempMedia"
        @refetch="getAllLibraryFiles"
      ></library-image-modal>
    </b-card>
  </b-overlay>
</template>
<script>
export default {
  title: "Library",
  mounted() {
    this.getAllLibraryFiles();
  },
  data() {
    return {
      isLoading: false,
      libraryList: null,
      count: 20,
      pageNumber: 1,
      totalCount: null,
      tempMedia: null,
    };
  },
  watch: {
    pageNumber: {
      handler(val) {
        this.getAllLibraryFiles();
      },
    },
  },
  methods: {
    copyUrl(imageUrl) {
      if (navigator) {
        navigator.clipboard.writeText(imageUrl);
        this.$toast({
          component: ToastificationContent,
          position: "bottom-center",
          props: {
            title: "Operation Was Successful",
            icon: "CheckIcon",
            variant: "success",
            text: "Link Copied Successfully",
          },
        });
      }
    },
    openImageDetails(image) {
      this.tempMedia = JSON.parse(JSON.stringify(image));
      this.$bvModal.show("details-modal");
    },
    isImage(media) {
      return media?.pictureUrl.match(/\.(jpeg|jpg|gif|png)$/) != null;
    },
    isPdf(media) {
      return media?.pictureUrl.match(/\.(pdf)$/) != null;
    },
    handleImageUrl(image) {
      return `${Helper.baseUrl}/${image.pictureUrl}`;
    },
    async getAllLibraryFiles() {
      try {
        this.isLoading = true;
        let _this = this;
        let getLibraryFiles = new GetLibraryFiles(_this);
        getLibraryFiles.setRequestParam({
          count: _this.count,
          pageNumber: _this.pageNumber,
        });
        await getLibraryFiles.fetch((response) => {
          if (response.isSuccess) {
            _this.libraryList = response.data.medias;
            _this.totalCount = response.data.mediasCount;
          }
        });
      } catch (error) {
        console.error(error);
      } finally {
        this.isLoading = false;
      }
    },
  },
  computed: {
    mediaType() {
      if (this.tempMedia) {
        if (this.isImage(this.tempMedia)) {
          return "image";
        } else if (this.isPdf(this.tempMedia)) {
          return "doc";
        } else {
          return "video";
        }
      }
    },
  },
  components: {
    BContainer,
    BPagination,
    BFormRow,
    BCard,
    BMedia,
    BAvatar,
    BCardText,
    BTable,
    BMediaAside,
    BMediaBody,
    BModal,
    BForm,
    BRow,
    BCol,
    BFormGroup,
    BFormInput,
    BImg,
    BFormFile,
    BLink,
    BButton,
    BFormTextarea,
    BOverlay,
    LibraryImageModal,
  },
};
import { GetLibraryFiles } from "@/libs/Api/Media";
import ToastificationContent from "@core/components/toastification/ToastificationContent";
import Helper from "@/libs/Helper";
import LibraryImageModal from "@/views/components/utils/LibraryImageModal.vue";
import {
  BContainer,
  BPagination,
  BFormRow,
  BCard,
  BMedia,
  BAvatar,
  BCardText,
  BModal,
  BTable,
  BMediaAside,
  BMediaBody,
  BForm,
  BRow,
  BCol,
  BFormGroup,
  BFormInput,
  BImg,
  BFormFile,
  BLink,
  BButton,
  BFormTextarea,
  BOverlay,
} from "bootstrap-vue";
</script>
